const state = {
  user_data: {},
};

const getters = {
  user_data(state) {
    return state.user_data;
  },
};

const mutations = {
  USER(state, data) {
    state.user_data = data;
  },
};

const actions = {
  async get_users(_, query) {
    return window.axios.get("/admin/all-users" + query);
  },
  async add_minutes(_, form) {
    return window.axios.post("/admin/users/add-minutes", form);
  },
  async delete_user(_, form) {
    return window.axios.post("/user/delete-account", form);
  },
  async filter_users(_, query) {
    return window.axios.get("/admin/all-users" + query);
  },
  async suspend_user(_, form) {
    return window.axios.post("/admin/suspend-user", form);
  },
  async add_user_to_account(_, form) {
    return window.axios.post("/accounts/add-account", form);
  },
  async update_password(_, form) {
    return window.axios.post("/admin/users/update-password", form);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
