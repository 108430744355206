import auth from "../middleware/auth";

const routes = [
  {
    path: "/plans",
    name: "Plans",
    component: () => import("../../views/protected/PlansPage"),
    meta: {
      layout: () => import("../../layouts/protected"),
      middleware: [auth],
      title: "Plans",
    },
  },
];

export default routes;
