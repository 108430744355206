<template>
  <v-app>
    <component :is="layout">
      <router-view></router-view>
    </component>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      timerId: null,
    };
  },
  created() {
    this.timerId = setInterval(() => {
      if (this.loading !== false) {
        this.$store.commit("auth/LOADING", false, { root: true });
        clearInterval(this.timerId);
      }
    }, 10000);
  },
  computed: {
    layout() {
      return this.$route.meta.layout;
    },
    loading() {
      return this.$store.state.auth.loading;
    },
  },
  beforeDestroy() {
    clearInterval(this.timerId);
  },
};
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
