import Vue from "vue";
import VueRouter from "vue-router";
import protectedRoutes from "./protected";
import publicRoutes from "./public";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/login",
  },
  ...protectedRoutes,
  ...publicRoutes,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const nextFactory = (context, middleware, index) => {
  const subsequentMiddleware = middleware[index];
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    context.next(...parameters);

    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
};

router.beforeEach((to, from, next) => {
  if (to.meta["middleware"]) {
    const middleware = to.meta["middleware"];

    const context = {
      to,
      from,
      next,
      store,
    };

    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({
      ...context,
      next: nextMiddleware,
    });
  }
  next();
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = to.meta["title"];
  });
});

export default router;
