<template>
  <v-container fluid>
    <v-overlay v-model="loading">
      <v-progress-circular
        indeterminate
        color="primary"
        size="40"
      ></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "LoadingProgress",
  data() {
    return {};
  },
  computed: {
    ...mapGetters("auth", ["loading"]),
  },
};
</script>

<style scoped></style>
