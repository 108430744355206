import dashboard from "./dashboard";
import recordings from "./recordings";
import users from "./users";
import profile from "./profile";
import payments from "./payments";
import partners from "./partners";
import plans from "./plans";

const routes = [
  ...dashboard,
  ...recordings,
  ...users,
  ...profile,
  ...payments,
  ...partners,
  ...plans,
];

export default routes;
