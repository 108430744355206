import auth from "../middleware/auth";

const routes = [
  {
    path: "/payment-history",
    name: "PaymentHistory",
    component: () => import("../../views/protected/PaymentsPage"),
    meta: {
      title: "Payments",
      middleware: [auth],
      layout: () => import("../../layouts/protected"),
    },
  },
];

export default routes;
