import auth from "../middleware/auth";

const routes = [
  {
    path: "/all-users",
    name: "Users",
    component: () => import("../../views/protected/UsersPage"),
    meta: {
      title: "All users",
      middleware: [auth],
      layout: () => import("../../layouts/protected"),
    },
  },
  {
    path: "/user/:id",
    name: "UserProfile",
    component: () => import("../../components/UserProfile"),
    meta: {
      title: "User Details",
      middleware: [auth],
      layout: () => import("../../layouts/protected"),
    },
  },
];

export default routes;
