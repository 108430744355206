const state = {};

const getters = {};

const mutations = {};

const actions = {
  async get_payments(_, query) {
    return window.axios.get("/payments/admin/get-payments" + query);
  },
  async add_payment(_, form) {
    return window.axios.post("/payments/" + form.upgrade_id + "/pay", form);
  },
  async delete_payment(_, payment) {
    return window.axios.delete("/payments/admin/" + payment.id + "/delete");
  },
  async get_plans() {
    return window.axios.get("/upgrades");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
