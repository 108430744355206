const state = {};

const getters = {};

const mutations = {};

const actions = {
  async get_partners() {
    return window.axios.get("/partners");
  },
  async add_partner(_, form) {
    return window.axios.post("/admin/partners/create", form);
  },
  async update_partner(_, partner) {
    return window.axios.patch(
      "/admin/partners/" + partner.id + "/update",
      partner
    );
  },
  async delete_partner(_, partner) {
    return window.axios.delete("/admin/partners/" + partner.id + "/delete");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
