import Vue from "vue";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

const options = {
  confirmButtonColor: "#5968fa",
  cancelButtonColor: "#ff7674",
};

Vue.use(VueSweetalert2, options);

export default class ResponseHelper {
  coreResponse = (title, message, type) => {
    if (Array.isArray(message)) {
      // noinspection JSCheckFunctionSignatures
      return Vue.swal({
        title: title,
        icon: type,
        html: message.join("<br>"),
      });
    }
    // noinspection JSCheckFunctionSignatures
    return Vue.swal(title, message, type);
  };

  sendSuccess = (response) => {
    this.coreResponse("Success", response.message, "success");
  };

  sendError = (error) => {
    let message = "";
    let status = error.response.status;
    if (status === 422) {
      let errors = error.response.data.errors;
      errors = Object.values(errors);
      message = errors.flat();
    } else if (status === 500) {
      message =
        "Something went wrong, please try again later or contact support";
    } else {
      message = error.response.data.message;
    }

    return this.coreResponse("Error", message, "error");
  };

  staticError = (message) => {
    return this.coreResponse("Error", message, "error");
  };
}
