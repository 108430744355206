import auth from "../middleware/auth";

const routes = [
  {
    path: "/my-recordings",
    name: "MyRecordings",
    component: () => import("../../views/protected/MyRecording"),
    meta: {
      title: "My Recordings",
      middleware: [auth],
      layout: () => import("../../layouts/protected"),
    },
  },
  {
    path: "/received-recordings",
    name: "ReceivedRecordings",
    component: () => import("../../views/protected/ReceivedRecording"),
    meta: {
      title: "Received Recordings",
      middleware: [auth],
      layout: () => import("../../layouts/protected"),
    },
  },
  {
    path: "/requested-links",
    name: "RequestedLinks",
    component: () => import("../../views/protected/RequestedLinks"),
    meta: {
      title: "Requested Links",
      middleware: [auth],
      layout: () => import("../../layouts/protected"),
    },
  },
  {
    path: "/record-screen",
    name: "RecordScreen",
    component: () => import("../../views/protected/RecordScreen"),
    meta: {
      title: "Record Screen",
      layout: () => import("../../layouts/public"),
    },
  },
  {
    path: "/my-recording-details/:id",
    name: "MyRecordingDetails",
    component: () => import("../../components/MyRecordingDetails"),
    meta: {
      title: "Play Video",
      layout: () => import("../../layouts/protected"),
      middleware: [auth],
    },
  },
];

export default routes;
