// const axios = window.axios;

const state = {};

const getters = {};

const mutations = {};

const actions = {
  async create_plan(_, form) {
    return window.axios.post("/upgrades/create", form);
  },
  async get_plans() {
    return window.axios.get("/upgrades");
  },
  async update_plan(_, form) {
    return window.axios.patch(`/upgrades/${form.id}/update`, form);
  },
  async delete_plan(_, form) {
    return window.axios.delete(`/upgrades/${form.id}/delete`);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
