import guest from "../middleware/guest";

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("../../views/Login"),
    meta: {
      title: "Admin::Login",
      middleware: [guest],
      layout: () => import("../../layouts/public"),
    },
  },
];

export default routes;
