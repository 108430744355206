const state = {
  token: null,
  user: {},
  loading: false,
};

const getters = {
  user(state) {
    return state.user;
  },
  loading(state) {
    return state.loading;
  },
};

const mutations = {
  ACCESS_TOKEN(state, data) {
    state.token = data;
  },
  USER_DATA(state, data) {
    state.user = data;
  },
  LOADING(state, data) {
    state.loading = data;
  },
  LOGOUT(state) {
    state.token = null;
    state.user = {};
  },
};

const actions = {
  async login_admin({ commit }, form) {
    const response = await window.axios.post("/admin/auth/login", form);
    commit("ACCESS_TOKEN", response.data.token);
    commit("USER_DATA", response.data);
    return response;
  },
  async add_account(_, form) {
    return window.axios.post("/auth/register", form);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
