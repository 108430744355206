const state = {};

const getters = {
  user(state, getters, rootState, rootGetters) {
    return rootGetters["auth/user"];
  },
};

const mutations = {};

const actions = {
  async update_profile({ commit }, form) {
    const response = await window.axios.post("/user/update", form);
    commit("auth/USER_DATA", response.data, { root: true });
    return response;
  },
  async change_password(_, form) {
    return window.axios.post("/user/change-password", form);
  },
  async add_minutes(_, form) {
    return window.axios.post("/upgrades/free-minutes", form);
  },
  async get_company_setup() {
    return window.axios.get("/upgrades/company/account-setup");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
