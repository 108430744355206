const state = {
  recordDetails: {},
  record_link: "",
  user_details: {},
};

const getters = {
  recordDetails(state) {
    return state.recordDetails;
  },
  record_link(state) {
    return state.record_link;
  },
  user_details(state) {
    return state.user_details;
  },
};

const mutations = {
  SHOW_DETAILS(state, data) {
    state.recordDetails = data;
  },
};

const actions = {
  async my_recordings(_, query) {
    return window.axios.get("/recordings/my-recordings" + query);
  },
  async received_recordings(_, query) {
    return window.axios.get("/recordings/received-recordings" + query);
  },
  async requested_links(_, query) {
    return window.axios.get("/recordings/requested-links" + query);
  },
  async new_request(_, form) {
    return window.axios.post("/recordings/new-request", form);
  },
  async delete_requested_link(_, link_id) {
    return window.axios.delete("/categories/" + link_id + "/delete");
  },
  async update_recording(_, form) {
    return window.axios.patch("/recordings/update-recording/" + form.id, form);
  },
  async delete_recording(_, record) {
    return window.axios.delete("/recordings/" + record.id + "/delete");
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
